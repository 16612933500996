.headerBox {
  width: 100%;
  /* background-color: #f8f4f4; */
  height: 55px;
  margin-top: 8px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 20px;
  display: flex;
}

.title {
  margin-top: 10px;
}

.headerBox .rightDiv {
  display: flex;
  position: absolute; 
  right: 0;
  height: 50px;
  margin-top: -2px;
  padding: 5px;
  margin-right: 20px;
}

.searchbar {
  height: 40px !important;
  width: 400px;
}

.headerBox .rightDiv > * {
  margin-left: 10px;
}

#searchBtn {
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.backBtn {
  margin-right: 10px;
  margin-left: -10px;
  margin-top: 3px;
  cursor: pointer;
}