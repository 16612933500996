.permission {
  padding-left: 10px;
  display: inline;
  margin: 10px 0 30px 0;
}

.permissionsList {
  margin-top: -10px;
}

.pemissionheading {
  margin-top: 10px !important;
}

.permissionModule {
  padding-bottom: 15px;
}