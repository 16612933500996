.loader {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader > img {
  width: 900px;
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.loader .hidden {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}